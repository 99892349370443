import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/layouts/text.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`DISCLAIMER`}</strong>{`: this roadmap is subject to change.`}</p>
    <p>{`This roadmap loosely tracks `}<a parentName="p" {...{
        "href": "https://github.com/orgs/fullcalendar/projects/1"
      }}>{`FullCalendar Planning spreadsheet`}</a>{`.
FullCalendar `}<a parentName="p" {...{
        "href": "https://github.com/fullcalendar/fullcalendar/milestone/72"
      }}>{`bugfixes`}</a>{`, `}<a parentName="p" {...{
        "href": "https://github.com/fullcalendar/fullcalendar/milestone/77"
      }}>{`minor features`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://github.com/fullcalendar/fullcalendar/issues?q=is%3Aissue+is%3Aopen+performance+label%3APerformance"
      }}>{`performance improvements`}</a>{` and will be released throughout each quarter.`}</p>
    <p><strong parentName="p">{`2024 - Q3`}</strong></p>
    <ul>
      <li parentName="ul">{`FullCalendar `}<a parentName="li" {...{
          "href": "https://github.com/fullcalendar/fullcalendar/milestone/51"
        }}>{`v7`}</a>
        <ul parentName="li">
          <li parentName="ul">{`DOM restructure & CSS refactor to leverage flexbox, eliminating `}<inlineCode parentName="li">{`<table>`}</inlineCode>{` markup`}</li>
          <li parentName="ul">{`Accessibility improvements `}{/* role: a11y expert */}</li>
          <li parentName="ul">{`Fully responsive views (except `}<inlineCode parentName="li">{`resource-timeline`}</inlineCode>{` for now)`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`resource-timeline`}</inlineCode>{` view virtual rendering for resources (`}<strong parentName="li">{`not`}</strong>{` time axis just yet)`}
            {/* iOS scrollbar problems */}
            {/* darkMode? */}
          </li>
        </ul>
      </li>
      <li parentName="ul">{`Website `}{/* role: integrations expert */}
        <ul parentName="li">
          <li parentName="ul">{`Searchable docs (`}<a parentName="li" {...{
              "href": "https://github.com/fullcalendar/fullcalendar/issues/4196"
            }}>{`#4196`}</a>{`)`}</li>
          <li parentName="ul">{`Refactor docs to use same metaframework as top-level site (`}<a parentName="li" {...{
              "href": "https://github.com/fullcalendar/fullcalendar/issues/6221"
            }}>{`#6221`}</a>{`)`}</li>
          <li parentName="ul">{`Dark mode for website`}</li>
          <li parentName="ul">{`Improved demos page`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/fullcalendar/temporal-polyfill"
        }}>{`temporal-polyfill`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Update to latest spec (July 2024)`}</li>
          <li parentName="ul">{`New website (a guide to the spec, how to use the polyfill) `}{/* role: design engineer */}</li>
        </ul>
      </li>
    </ul>
    <p><strong parentName="p">{`2024 - Q4`}</strong></p>
    <ul>
      <li parentName="ul">{`FullCalendar `}<a parentName="li" {...{
          "href": "https://github.com/fullcalendar/fullcalendar/milestone/73"
        }}>{`v7.5`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Resource grouping in vertical resource views`}</li>
          <li parentName="ul">{`Virtual rendering for dates/times/events in all views`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`resource-timeline`}</inlineCode>{` view full responsiveness`}</li>
          <li parentName="ul">{`Highlight cells on hover`}</li>
          <li parentName="ul">{`Infinite scroll while scrolling through dates (`}<strong parentName="li">{`not`}</strong>{` resource list just yet)`}</li>
          <li parentName="ul">{`Advanced accessibility interactions (roving tab index, date select) `}{/* role: a11y expert */}</li>
        </ul>
      </li>
      <li parentName="ul">{`Examples `}{/* role: integrations expert */}
        <ul parentName="li">
          <li parentName="ul">{`Examples for various UI frameworks, toolbar customization (MUI, Chakra, Mantine, etc)`}</li>
          <li parentName="ul">{`Examples for creating resource pagination in `}<inlineCode parentName="li">{`resource-timeline`}</inlineCode>{` view (stopgap for `}<a parentName="li" {...{
              "href": "https://github.com/fullcalendar/fullcalendar/issues/4831"
            }}>{`#4831`}</a>{`)`}</li>
          <li parentName="ul">
            <a href='https://github.com/wix/react-native-calendars' target='_blank' rel='nofollow'>react-native-calendars</a> adapter or DX utilities (stopgap for <a href='https://github.com/fullcalendar/fullcalendar/issues/7076'>#7076</a>)
          </li>
        </ul>
      </li>
      <li parentName="ul">{`Website `}{/* role: design engineer */}
        <ul parentName="li">
          <li parentName="ul">{`Improved responsiveness for all pages`}</li>
          <li parentName="ul">{`Improved look & feel for docs`}</li>
        </ul>
      </li>
    </ul>
    <p><strong parentName="p">{`2025 - Q1`}</strong></p>
    <ul>
      <li parentName="ul">{`FullCalendar `}<a parentName="li" {...{
          "href": "https://github.com/fullcalendar/fullcalendar/milestone/74"
        }}>{`v8`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Internal data refactor to leverage (P)react state instead of unweildy OOP data stores`}</li>
          <li parentName="ul">{`Internal usage of `}<a parentName="li" {...{
              "href": "https://github.com/fullcalendar/temporal-polyfill"
            }}>{`temporal-polyfill`}</a>{` (eliminates need for timezone plugins)`}</li>
          <li parentName="ul">{`Hijri and Jalaali calendar systems`}</li>
          <li parentName="ul">{`TypeScript definition improvements`}</li>
          <li parentName="ul">{`Restore dual rendering for React/Preact`}</li>
          <li parentName="ul">{`Leverage HTML Drag and Drop API instead of home-grown solution`}</li>
          <li parentName="ul">{`Infinite scroll with while scrolling through resources in `}<inlineCode parentName="li">{`resource-timeline`}</inlineCode>{` view`}</li>
          <li parentName="ul">{`Resource pagination for `}<inlineCode parentName="li">{`resource-timeline`}</inlineCode>{` view`}</li>
        </ul>
      </li>
    </ul>
    <p><strong parentName="p">{`2025 - Q2`}</strong></p>
    <ul>
      <li parentName="ul">{`FullCalendar `}<a parentName="li" {...{
          "href": "https://github.com/fullcalendar/fullcalendar/milestone/75"
        }}>{`v9`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Swipe/sliding effect when navigating prev/next`}</li>
          <li parentName="ul">{`Navigate prev/next when dragging event to edges`}</li>
          <li parentName="ul">{`Full separation of calendar state from view layer, enabling:`}
            <ul parentName="li">
              <li parentName="ul">{`Real Vue/Angular components as opposed to an embedded Preact adapters`}</li>
              <li parentName="ul">{`Svelte/SolidJS implementations that match each framework's impressive speed`}</li>
              <li parentName="ul">{`Idiomatic APIs tailored for each UI framework`}</li>
              <li parentName="ul">{`Headless UI library (TBD)`}
                {/* package rearrangement */}
                {/* how styles are imported, nonce problems */}
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <p><strong parentName="p">{`2025 - Q3`}</strong></p>
    <ul>
      <li parentName="ul">{`FullCalendar `}<a parentName="li" {...{
          "href": "https://github.com/fullcalendar/fullcalendar/milestone/76"
        }}>{`v10`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Building on the refactoring from v9, a React Native component (TBD)`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      